@font-face {
  font-family: "SF-Pro-Display-Bold";
  src: url("./fonts/SF-Pro-Display-Bold.otf") format("opentype");
}

@font-face {
  font-family: "SF-Pro-Display-Medium";
  src: url("./fonts/SF-Pro-Display-Medium.otf") format("opentype");
}

@font-face {
  font-family: "SF-Pro-Display-Regular";
  src: url("./fonts/SF-Pro-Display-Regular.otf") format("opentype");
}

@font-face {
  font-family: "SF-Pro-Display-Semibold";
  src: url("./fonts/SF-Pro-Display-Semibold.otf") format("opentype");
}
